<template>
  <section >
    <h2 class="titulo-links-escolha">CADASTRAR TURMA</h2>
    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-8 lg:col-3">
            <label>Selecione a Escola</label>
            <select class="p-inputtext p-component" v-model="escolaInfo"
              style="appearance: revert !important;width: 100% !important;" >
              <option value="" disabled selected> -- Escolha uma Escola -- </option>
              <option v-for="n in escolaALL" :key="n" :value="n.id" >{{n.nome}}</option>
            </select>
          </div>
          <div class="field col-12 md:col-6 lg:col-2">
            <label>Nome da turma</label>
            <pm-InputText  v-model="turma.nome" />
          </div>
          <div class="field col-12 md:col-4 lg:col-1">
            <label>Selecione o Turno</label>
            <select class="p-inputtext p-component" v-model="horarioSelecionado"
              style="appearance: revert !important;width: 100% !important;" >
              <option v-for="n in horarios" :key="n" :value="n.nome" >{{n.nome}}</option>
            </select>
          </div>

          <div class="field col-12 md:col-5 lg:col-3">
            <label>Selecione o Segmento</label>
            <select class="p-inputtext p-component" v-model="segmentoObject" @change="segmentoInfo = segmentoObject.id; listaSerie(segmentoInfo)"
              style="appearance: revert !important;width: 100% !important;" >
              <option value="" disabled selected> -- Escolha o Segmento -- </option>
              <option v-for="n in segmentoALL" :key="n" :value="n" >{{n.nome}}</option>
            </select>
          </div>
          <div class="field col-12 md:col-7 lg:col-3" v-if="segmentoInfo">
            <label>Selecione o Período:</label>
            <select class="p-inputtext p-component"
              style="appearance: revert !important;width: 100% !important;" v-model="serieInfo" >
              <option value="" disabled selected> -- Escolha o Período -- </option>
              <option v-for="a in serieALL" :key="a.id" :value="a.id" >{{a.nome}}</option>
            </select>
          </div>


          <div class="col-md-12" style="margin-left: 10px;" v-if="segmentoObject != '' && segmentoObject.tipo == 3">
            <!-- <label
            >Essa turma faz parte do 2° semestre do ano?
            <pm-InputSwitch
              v-model="turma.segundo_semestre"
              class="small-switch"
              style="vertical-align: middle !important"
          /></label> -->
            <div class="field-radiobutton" style="margin-top: 24px">
              <label>Semestre:</label>&nbsp;
              <label for="solteiro">1° semestre</label>
              <pm-RadioButton
                v-model="turma.segundo_semestre"
                name="1"
                value="false"
                style="margin-left: 7px"
              />
              <label >2° semestre</label>
              <pm-RadioButton
                v-model="turma.segundo_semestre"
                name="2"
                value="true"
                style="margin-left: 7px"
              />
            </div>
          </div>



        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="this.$router.push({name: 'coordenacao-turma-hab'});" />

          <pm-Button   class="p-button-success"
            label="Cadastrar" @click="cadastrar()" v-if="horarioSelecionado && turma.nome && segmentoInfo && serieInfo && escolaInfo"
            icon="pi pi-check" iconPos="right"
          />
        </div>
      </template>
    </pm-Card>


    <pm-Dialog  header="Painel da Turma" v-model:visible="display"  :maximizable="true">
      <div>
        <div class="alert alert-success">
          <strong>Sucesso!</strong> Turma criada.
        </div>
        <p> Faça as Operações necessarias na Turma abaixo.</p>
        <pm-Button  type="button" @click="this.$router.push({name: 'coordenacao-turma-hab'});"
          icon="pi pi-angle-left"
          label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(0)"
          icon="pi pi-id-card"
          label="ADICIONE DISCIPLINAS" title="DISCIPLINAS"  class="p-button-sm p-button-info btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(1)"
          icon="pi pi-id-card"
          label="ADICIONE PROFESSOR" title="PROFESSOR"  class="p-button-sm p-button-info btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(2)"
          icon="pi pi-id-card"
          label="ADICIONE ALUNOS" title="ALUNOS"  class="p-button-sm p-button-info btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>
      </div>
    </pm-Dialog>
  </section >
</template>

<script>

import { Turma } from "@/class/turma.js";
import { Escola } from "@/class/escolas";
import { LoginInfo } from "@/class/login";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { Calendario } from "@/class/calendario.js";
import { Configuracao } from "@/class/configuracao.js";

import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  components: {

  },
  name: 'form-elements',
  data () {
    return {
      turma: {
        ano: null,
        escola_id: null,
        segmento_id: null,
        serie_id: null,
        nome: null,
        turno: null,
        user_id: store_token_info.usuario_logado,
        segundo_semestre:'false',
      },
      id:0,
      horarios: [],
      horarioSelecionado:'',
      escolaInfo : '',
      escolaALL : [],
      segmentoInfo : '',
      segmentoALL : [],
      segmentoObject: '',
      serieInfo : '',
      serieALL : [],
      isLoading: false,
      errorMessages: ['Digite um CEP válido!'],
      display:false,
      turma_aux: {
        escola_id: null,
        segmento_id: null,
        serie_id: null,
        nome: null,
        turno: null,
      },
    }
  },
  methods: {
    clear (field) {
      this[field] = ''
    },
    irOutraPg(pg){
      if(pg == 0){
       this.$router.push({name: 'turma-add-disciplina-coordenacao', params: {etapa_id: this.turma_aux.segmento_id, periodo_id: this.turma_aux.serie_id, turma_id: this.id,
       escola_id: this.turma_aux.escola_id, turno: this.turma_aux.turno} });
      }
      if(pg == 1){
       this.$router.push({name: 'turma-add-professor-coordenacao', params: {etapa_id: this.turma_aux.segmento_id, periodo_id: this.turma_aux.serie_id, turma_id: this.id,
       escola_id: this.turma_aux.escola_id, turno: this.turma_aux.turno} });
      }
      if(pg == 2){
       this.$router.push({name: 'turma-add-aluno-coordenacao',  params: {etapa_id: this.turma_aux.segmento_id, periodo_id: this.turma_aux.serie_id, turma_id: this.id,
       escola_id: this.turma_aux.escola_id, turno: this.turma_aux.turno} });
      }
    },
    async buscarCalendarioAtual() {
      const data = await Calendario.calendarioAtual();
      this.turma.ano = data.data[0].ano;
    },
    async buscarEscolaALL() {

      const token = sessionStorage.getItem("token");
      const whoiam = await LoginInfo.WhoIam(token);

      const user = whoiam.data.usuario.id;
      const coordenacao =  sessionStorage.getItem("coordenacao");

      const anoSelecionado =  sessionStorage.getItem("anoSelecionado");

      const payload = {
        servidor_id: user,
        ano: anoSelecionado,
        tipo: coordenacao,
      };
      const data = await Escola.obtemTodosCoordenacao(payload);
      if (payload.tipo == "1") {
        this.escolaALL.push(data.data);
      }
      else {
        for (const el of data.data) {
          const data1 = await Escola.obtemUm(el.id);
          const novo = {
            id_: el.id,
            id: el.id,
            logradouro:el.logradouro,
            bairro: el.bairro,
            cep: el.cep,
            cidade: el.cidade,
            cnpj: el.cnpj,
            email: el.email,
            estado: el.estado,
            nome: el.nome,
            numero: el.numero,
            escola: data1.data,
          };
          this.escolaALL.push(novo);
        };
        if (this.escolasALL.length) {
          this.$vaToast.init({
            message: "O servidor não está cadastrado em nenhuma escola!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            color:'success',
            fullWidth: false,
          });
          return false;
        }
      }
      // let data = await Escola.obtemTodos();
      //this.escolaALL = data.data;
    },
    async buscarSegmentoALL() {
      const data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = data.data;
    },

    async listaSerie (id) {
      const data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      this.serieALL = data.data;
      return this.seriesALL;
    },

    async buscarTurnoALL() {
      /*let data = await Configuracao.obtemTodos();
      this.horarios = data.data[0].turnos;*/
      const turnos = [{nome: "Matutino"}, {nome: "Vespertino"}, {nome: "Noturno"}, {nome: "Diurno"}]
      this.horarios = turnos;
    },
    async cadastrar() {
     try {
        this.turma.turno = this.horarioSelecionado;
        this.turma.segmento_id = this.segmentoInfo;
        this.turma.serie_id = this.serieInfo;
        this.turma.escola_id = this.escolaInfo;
        const data = await Turma.cadastrar(this.turma);
        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          color:'success',
          fullWidth: false,
        });
        this.id = data.data.id;
        this.turma_aux.turno = this.turma.turno;
        this.turma_aux.segmento_id = this.turma.segmento_id;
        this.turma_aux.serie_id = this.turma.serie_id;
        this.turma_aux.escola_id = this.turma.escola_id;

        this.display = true;

        this.turma.turno = null;
        this.turma.segmento_id = null;
        this.turma.serie_id = null;
        this.turma.escola_id = null;
        this.turma.ano = null;
        this.turma.nome = null;
        //this.$router.push({name: 'coordenacao-turma-hab'});
      }
      catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);

          });
        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
        this.$vaToast.init({
          message: "Erro ao cadastrar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
    },
  },
  mounted() {
    this.buscarEscolaALL();
    this.buscarSegmentoALL();
    this.buscarCalendarioAtual();
    this.buscarTurnoALL();
  },
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
